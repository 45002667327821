.VisionContainer{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    overflow-x: hidden;
    .VisionRow{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 70vh;
    }
    .VisionRight{
        width: 50%;
        min-height: 70vh;
        img{
            width: 100%;
            height: 70vh;
            object-fit: cover;
        }
    }
    .VisionLeft{
        min-height: 70vh;
        width: 50%;
        background-color: #0066B0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5rem;
        flex-direction: column;
    }

}
@media screen and (max-width : 990px) {
    .VisionContainer{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 70vh;
        overflow-x: hidden;
        .VisionRow{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 70vh;
            flex-direction: column-reverse;
            
        }
        .VisionRight{
            width: 100%;
            min-height: 50vh;
            img{
                width: 100%;
                height: 50vh;
                object-fit: cover;
            }
        }
        .VisionLeft{
            min-height: 50vh;
            width: 100%;
            background-color: #0066B0;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
            flex-direction: column;
        }
    
    }
    
}