.MissionContainer{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    overflow-x: hidden;
    .MissionRow{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 70vh;
    }
    .MissionLeft{
        width: 50%;
        min-height: 70vh;
        img{
            width: 100%;
            height: 70vh;
            object-fit: cover;
        }
    }
    .MissionRight{
        min-height: 70vh;
        width: 50%;
        background-color: #F5822F;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5rem;
        flex-direction: column;
    }

}
@media screen and (max-width : 990px) {
    .MissionContainer{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 70vh;
        overflow-x: hidden;
        .MissionRow{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 70vh;
            flex-direction: column;
        }
        .MissionLeft{
            width: 100%;
            min-height: 50vh;
            img{
                width: 100%;
                height: 50vh;
                object-fit: cover;
            }
        }
        .MissionRight{
            min-height: 50vh;
            width: 100%;
            background-color: #F5822F;
            color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
            flex-direction: column;
        }
    
    }
    
}