.DiscoverContainer{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0066B0;
    color: #ffffff;
    padding-bottom: 4rem;
    .Container{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .row{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin-top: 3rem;

            .Cards{
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                color: white;
                min-height: 50vh;
              
                
                .ImageContainer{
                    overflow: hidden;
                    border-radius: 10px;
                    min-height: 50vh;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    // z-index: 2;
                    background: transparent;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    img{
                        width: 100%;
                        min-height: 50vh;
                        border-radius: 10px;
                        filter: brightness(.5);
                        
                        object-fit: cover;
                    transition: 250ms ease-out;
                    
                    &:hover{
                        transform: scale(1.2);
                        transition: 250ms ease-in;
                    }
                }

 
                }
                .CardContents{
                    position: absolute;
                    // top: 0;
                   
                    width: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin:  auto 0;
                    text-align: center;
                    max-height: 50vh;
                    h5{
                        margin: auto;
                    }
                    p{
                        line-height: normal !important;
                        margin: auto;
                    }

                    
                }
                
            }
        }
    }
}

@media screen and (max-width : 1000px) {
    .Container{
        width: 90% !important;
        h1{
            font-size: 35px !important;
            line-height: normal !important;
        }
        .row{
            flex-direction: column;
            .Cards{
                width: 100% !important;
                margin: 2rem 0;
            }
        }
    }
}