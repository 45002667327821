.CreateContainer{
    min-height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0000;
    background: #F5822F;
    padding: 3rem 0 ;
    
    flex-direction: column;
    
    
      h1{
          color: black;
          margin: auto;
          
      }
    

        .Cards{
            width: 100%;
            min-height: 50vh;
           
            display: flex;
            align-items: center;
            justify-content: start;
            flex-direction: column;
            background: #0066B0;
            border: 1px solid #F5822F;
            color: white;
            padding: 2rem 0;
            text-align: center;
            transition: 250ms ease-out;
            position: relative;
            
            .ImageContainer{
                width: 90%;
                display: flex;
                height: 10vh;
                margin-bottom: 1rem;
                
                
             
                img{
                    height: 10vh;
                   
                    margin: 0 auto;
                    object-fit: contain;
                }
            }
            .CardContent{
            

                width: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h3{
                    font-size: 23px !important;
                    margin-bottom: 0 !important;
                }
            }
            &:hover{
                transition: 250ms ease-in;
                transform: scale(1.05);
                -webkit-box-shadow: 0px 0px 20px 5px #F5822F;
                -moz-box-shadow: 0px 0px 20px 5px #F5822F;
                box-shadow: 0px 0px 20px 5px #F5822F;
            }
        }
        // .Row1{
        //     width: 100%;
        //     align-items: center;
        //     justify-content: start;
        //     display: flex;
        //     .CreateContent{
        //         width: 50%;
        //         display: flex;
        //         justify-content: center;
        //         align-items: flex-start;
        //         flex-direction: column;
        //         h1{
        //             font-size: 55px;
        //         }
                
        //     }
        // }
            
        //     .row{  
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     width: 100%;
        //     .CreateVideo{
        //         width: 50%;
        //         align-items: center;
        //         justify-content: center;
        //         video{
        //             width: 100%;
        //         }
        //     }
        //     .TextContent{
        //         width: 50%;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         flex-direction: column;
        //         padding: 0 5rem;
        //     }
        // }
    }





@media screen and (max-width : 1600px) {
    .Cards{
        min-height: 55vh !important;
    }
    
}
@media screen and (max-width : 1417px) {
    .Cards{
        min-height: 60vh !important;
    }
    
}
@media screen and (max-width : 1200px) {
    .customCardContainer{
        margin-bottom: 2rem !important;
    }
    
}
@media screen and (max-width : 1000px) {
       
    .Cards{
        width: 100%   !important;
        margin: 2rem 0;
    }
    .CreateContent{
        width: 100% !important;
        h1{
            text-align: center;
        }
    }
    
    .row{
        flex-direction: column  !important;
        .CreateVideo{
            width: 100% !important;
            video{
                width: 100% !important;
        object-fit: contain !important;
    }
    
}
.TextContent{
    width: 100% !important ;
}
}
}