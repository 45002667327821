i{
  font-size: 30px;
  color: black;


}
a{
  text-decoration: none;
  color: black;

}
.container{
  width: 70% !important;
  
}
body{
  overflow-x: hidden;
}
.AppNavBar{
  position: sticky;
  top: 0;
  z-index: 100;
}